<template>
<span>

    <v-form ref="form" v-model="valid" lazy-validation autocomplete="off">
        <v-container fluid grid-list-md>
            <v-row>
                <v-col cols="10" xs="12" sm="12" md="8" lg="10" xl="10">
                    <v-row>

                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-text-field v-model="productbrand.prod_b_nome" :rules="rules.nomeRules" label="Nome" outlined required></v-text-field>
                        </v-col>

                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-text-field v-model="productbrand.prod_b_descricao" label="Descrição" outlined required></v-text-field>
                        </v-col>

                        <v-col cols="12">
                            <v-switch color="lime" v-model="productbrand.prod_b_status" label="Status"></v-switch>
                        </v-col>

                        <v-col cols="12" class="text-right">
                            <v-btn class="btn-salvar" :disabled="!valid" color="success" @click="onSubmit">
                                <v-icon class="mdi mdi-check"></v-icon>{{btnLabel}}
                            </v-btn>
                        </v-col>

                    </v-row>
                </v-col>

                <v-col cols="2" xs="12" sm="12" md="4" lg="2" xl="2">

                    <v-layout row wrap justify-center column>

                        <v-flex d-flex v-if="preview || productbrand.prod_b_logo" justify-center>
                            <v-card outlined>
                                <v-img v-if="preview" class="img-preview" :src="preview"></v-img>

                                <v-img v-if="productbrand.prod_b_logo && !preview" class="img-preview" :src="`${base_url}upload/product_brand/${productbrand.prod_b_logo}`"></v-img>

                                <v-card-actions class="justify-center">
                                    <v-btn icon>
                                        <v-icon @click="$refs.file.click()">cloud_upload</v-icon>
                                    </v-btn>
                                    <v-btn icon>
                                        <v-icon @click.prevent="removePreview">highlight_off</v-icon>
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-flex>

                        <v-flex d-flex justify-center pt-4>
                            <input type="file" ref="file" style="display:none;" id="files" @change="onFileChange" />
                            <v-btn v-if="!preview && !productbrand.prod_b_logo" color="blue-grey" class="btn-upload white--text" @click="$refs.file.click()">
                                Adicionar logo
                                <v-icon right dark>cloud_upload</v-icon>
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </v-col>
            </v-row>
        </v-container>
    </v-form>

</span>
</template>

<script>
import {
    URL_BASE
} from '../../../../config/configs'

export default {
    name: "FormProductBrandComponent",
    props: {
        productbrand: {
            require: false,
            type: Object | Array,
            default: () => {
                return {
                    prod_b_id: '',
                    prod_b_nome: '',
                    prod_b_slug: '',
                    prod_b_descricao: '',
                    prod_b_logo: '',
                    prod_b_banner: '',
                    prod_b_status: false,
                }
            }
        },
        update: {
            require: false,
            type: Boolean,
            default: false
        },
        btnLabel: {
            require: false,
            type: String,
            default: 'Salvar'
        },
        require: true,
    },
    created() {

    },
    computed: {

    },

    data() {
        return {
            valid: true,
            rules: {
                nomeRules: [
                    v => !!v || 'Campo Nome é obrigatório',
                    v => (v && v.length <= 100) || 'Nome precisa ter até 100 Caracteres'
                ]
            },
            upload: null,
            fileName: 'Selecione uma foto',
            preview: null,
            base_url: URL_BASE
        }
    },
    methods: {
        onSubmit() {

            if (this.$refs.form.validate()) {
                const action = this.update ? 'updateProductBrand' : 'storeProductBrand';
                const msg = this.update ? 'Dados atualizados com sucesso' : 'Cadastro efetuado com sucesso';

                const formData = new FormData();

                if (this.upload !== null)
                    formData.append('prod_b_logo', this.upload);

                formData.append('prod_b_id', this.productbrand.prod_b_id);
                formData.append('prod_b_nome', this.productbrand.prod_b_nome);
                formData.append('prod_b_descricao', this.productbrand.prod_b_descricao);
                formData.append('prod_b_banner', this.productbrand.prod_b_banner);
                formData.append('prod_b_status', this.productbrand.prod_b_status);

                this.$store.dispatch(action, formData)
                    .then(() => {
                        this.$swal({
                            position: "center",
                            icon: "success",
                            title: msg,
                            showConfirmButton: true,
                            timer: 6000
                        });
                        this.$router.push({
                            name: 'product-brands'
                        });
                    })
                    .catch(error => {
                        this.$swal({
                            position: "center",
                            icon: "error",
                            title: 'Erro',
                            text: 'Algo deu errado',
                            showConfirmButton: true,
                            timer: 6000
                        });
                        this.errors = error.response.data.errors
                    })
            } else {
                this.$swal({
                    position: "center",
                    icon: "warning",
                    title: 'Alguns campos requer sua atenção',
                    text: 'Verifique os campos em vermelho',
                    showConfirmButton: true,
                    timer: 6000
                });
            }

        },
        onFileChange(e) {
            let files = e.target.files || e.dataTransfer.files;

            if (!files.length)
                return;

            this.upload = files[0];
            this.fileName = this.upload.name;

            this.previewImage(files[0]);
        },
        previewImage(file) {
            let reader = new FileReader();
            reader.onload = (e) => {
                this.preview = e.target.result;
            };
            reader.readAsDataURL(file)
        },
        removePreview() {
            this.preview = null;
            this.upload = null;
        },
    },
    components: {

    }
};
</script>

<style scoped>
.theme--dark.v-sheet {
    background-color: #303030;
    border-color: #303030;
    color: #fff;
    box-shadow: none;
}

.btn-upload,
.btn-salvar {
    margin: 0;
}

/*Also*/
.fileinput-button input {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    opacity: 0;
    -ms-filter: 'alpha(opacity=0)';
    filter: 'alpha(opacity=0)';
    font-size: 200px;
    direction: ltr;
    cursor: pointer;
}

.img-preview {
    max-width: 100%;
    height: 120px;
}
</style>
